import { React, Modal, useEffect, useState, useContext, Link, _, toast } from 'commons';
import { useList, usePosition, useSecurity } from 'shared/hooks';
import { ReferentialContext } from 'shared/stores';
import { SheetApi } from 'shared/api';
import { getSheetStatus, getSheetStatusColor, getSheetStatusLabel, SHEET_ARCHIVED } from 'data/SheetStatus';
import { getSheetStateColor, getSheetStateLabel, REFERENTIALS } from 'shared/data';
import { TreeReferentialComponent, HtmlComponent, ListComponent, StatusComponent, FilterComponent, LoadButton } from 'shared/components';
import { Can, denyIfCantPerform, preventDefault, toggleArrayValue, compileDataToSelectOptions, FileUtils, convertCriteriesToSearchQuery, modalCustomStyles, AccountUtils } from 'shared/services';
import AffectationForm from 'views/push/List/Assignements/AffectationForm';

export default function SheetListStandard(props) {

    denyIfCantPerform(props, "sheet:list");

    const [initializePosition] = usePosition("sheet-list");
   
    const [referentialContext] = useContext(ReferentialContext);
    const sources = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_SOURCE], 'id', 'value');
    const transmitters = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TRANSMITTER], 'id', 'value');
    const text_types = compileDataToSelectOptions(referentialContext[REFERENTIALS.REFERENTIAL_TEXT_TYPE], 'id', 'value');
    const tree = _.cloneDeep(referentialContext["tree"]);
    const [user] = useSecurity();
    const [exporting, setExporting] = useState(false);
    const [affecting, setAffecting]= useState(false);
    const [currentSheet, setCurrentSheet] = useState(null);

    const columns = [
        {id: 'id', title: 'Numéro'},
        {id: 'title', title: 'Titre', render: (row) => <HtmlComponent>{row.title}</HtmlComponent>},
        {id: 'categories', title: 'Domaines', sortable: false, render: (row) => 
            <TreeReferentialComponent className="arborescence" items={tree} value={row.categories.map(e => e.id)} />
        },
        {id: 'text_date', title: 'Date du texte', format: 'date'},
        {id: 'publication_date', title: 'Date de publication', format: 'date'},
        {id: 'add_to_dispatch', title: 'Dépêche', format: 'checksingle'},
        {id: 'status', title: 'Statut', render: (row) => 
            <StatusComponent 
                value={getSheetStatusLabel(row.status)} 
                color={getSheetStatusColor(row.status)} 
            />
        },
        {id: 'state_synthesis', title: 'Niveau synthèse', render: (row) => 
            <StatusComponent 
                value={getSheetStateLabel(row.state_synthesis)} 
                color={getSheetStateColor(row.state_synthesis)} 
            />
        },
        {id: 'state_technical', title: 'Niveau technique', render: (row) => 
            <StatusComponent 
                value={getSheetStateLabel(row.state_technical)} 
                color={getSheetStateColor(row.state_technical)} 
            />
        },
        {id: 'nor', title: 'NOR'},
        {id: 'text_number', title: 'Numéro du texte'},
        {id: 'action', title: '', sortable: false, render: (row) => 
            <ul className="actions">
                <Can perform="sheet:read" yes={() => (<>
                    <li>
                        <Link data-info="Consulter la fiche" className="infobulle" to={"/sheets/" + row.id + "/preview"} id="preview-sheet">
                            <i className="icon-actions-consulter-fiche" aria-hidden="true"></i>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={(e) => downloadText(e, row)} title="Consulter le texte reglementaire" to={"/sheets/" + row.id} id={`download-text-${row.id}`}>
                            <i className="icon-actions-consulter-pdf" aria-hidden="true"></i>
                        </Link>
                    </li>
                </>)} />
                <Can perform="sheet:write" yes={() => (<>
                    <li>
                        <Link data-info="Modifier la fiche" className="infobulle" to={"/sheets/" + (row.is_private ? "private/" : "") + row.id} id="edit-sheet">
                            <i className="icon-actions-modifier" aria-hidden="true"></i>
                        </Link>
                    </li>
                    <li>
                        <Link onClick={(e) => archiveSheet(e, row)} title="Archiver la fiche" to={"/sheets/" + row.id}id={`archive-sheet-${row.id}`}>
                            <i className="icon-actions-archiver-fiche" aria-hidden="true"></i>
                        </Link>
                    </li>
                </>)} />
                {(AccountUtils.isAccountConsultant(row.confidential_account))
                    && <li>
                        <Link data-info="Modifier la fiche" className="infobulle" to={"/sheets/" + (row.is_private ? "private/" : "") + row.id} id="edit-sheet">
                            <i className="icon-actions-modifier" aria-hidden="true"></i>
                        </Link>
                    </li>
                }
                <Can perform="sheet:affect" yes={() => (<>
                    <li>
                        <a title="Affecter la fiche aux éléments sélectionnés" onClick={(e) => openAffectationForm(e, row)} href={"/sheets/" + row.id + "/affect"} id={`affect-sheet-${row.id}`}>
                            <i className="icon-actions-affecter" aria-hidden="true"></i>
                        </a>
                    </li>
                </>)} />
                {(
                    user.roles.includes("ROLE_LIBRARIAN")
                    || user.roles.includes("ROLE_ADMIN")
                    || user.roles.includes("ROLE_SUPER_ADMIN")
                ) && <li>
                    <Link onClick={(e) => toggleDispatch(e, row)} title="Ajouter/Retirer de la dépêche" to={"/sheets/" + row.id}id={`toggle-dispatch-${row.id}`}>
                        <i className="icon-actions-ajouter-retirer-depeche" aria-hidden="true"></i>
                    </Link>
                </li>}
            </ul>
        }
    ];  

    const [
        rows,
        totalrows,
        criterias,
        sorting,
        direction,
        limit,
        page,,,
        addCriteria,
        updateSorting,
        updateLimit,
        updatePage,
        submitSearch,
        loading,,
        refresh
    ] = useList("sheet-list", SheetApi, null, "title", {
        id: '',
        is_private: '0'
    });

    let filterType = {
        keyword: {type: "wildcard", fields: getKeywordFields()},
        id: {type: "match", fields: ["id"]},
        text_type: {type: "terms", fields: ["text_types"]},
        text_date: {type: "range", fields: ["text_date"]},
        publication_date: {type: "range", fields: ["publication_date"]},
        major_text: {type: "match", fields: ["major_text"], cast: "int"},
        repealed_text: {type: "match", fields: ["repealed_text"], cast: "int"},
        cut_into_requirements: {type: "match", fields: ["cut_into_requirements"], cast: "int"},
        text_number: {type: "match", fields: ["text_number"]},
        nor: {type: "match", fields: ["nor"]},        
        source: {type: "terms", fields: ["source"]},
        transmitter: {type: "terms", fields: ["transmitter"]},
        categories: {type: "terms", fields: ["categories"]},
        status: {type: "terms", fields: ["status"]},
        is_private: {type: "match", fields: ["is_private"], cast: "int"},
    };

    function load() {
        submitSearch(filterType, () => initializePosition(), false);
    }

    function archiveSheet(event, row) {
        event.preventDefault();
        SheetApi
            .bulkUpdate([row.id], {"status": SHEET_ARCHIVED})
            .then(() => {
                toast.success("Mise à jour effectuée.")
                refresh();
            });
    }

    function downloadText(event, row) {
        event.preventDefault();
        FileUtils.download(row.reglementary_text);
    }

    function toggleDispatch(event, row) {
        event.preventDefault();
        SheetApi
            .bulkUpdate([row.id], {"add_to_dispatch": row.add_to_dispatch ? 0 : 1})
            .then(() => toast.success("Mise à jour effectuée."));
    }

    function openAffectationForm(event, sheet) {
        event.preventDefault();
        setCurrentSheet(sheet);
        setAffecting(true);
    }

    function runExport(mode) {
        setExporting(true);
        const exportQuery = convertCriteriesToSearchQuery(criterias, filterType);
        const fileName = FileUtils.getFilePrefix() + "-fiches.xlsx";
        return SheetApi
            .exportSheets(exportQuery, fileName)
            .then(data => {
                toast.success("L'export a été initié, vous serez notifié lorsqu'il sera disponible.");
            })
            .catch((error) => {
                if (error.response.data.message) {
                    toast.error(error.response.data.message);
                } else {
                    toast.error("Une erreur est survenue");
                }               
            })
            .finally(() => setExporting(false));
    }

    function getKeywordFields() {
        return Array.isArray(criterias.keyword_fields) ? criterias.keyword_fields : ['title', 'reglementary_text', 'synthesis', 'technical_comment'];
    }

    // eslint-disable-next-line
    useEffect(load, []);

    return (
        <div className="bloc">
            <form className="form" onSubmit={(e) => preventDefault(e, submitSearch(filterType))}>
                <section className="filters">
                    <header>
                        <h2>Recherche</h2>
                    </header>
                    <div className="bg-gris-25">
                        <div className="row">
                            <FilterComponent type="text" name="id" onChange={value => addCriteria("id", value)} value={criterias.id} label="Numéro de la fiche" />
                            <FilterComponent 
                                type="keyword"
                                name="keyword"
                                label="Recherche libre" 
                                fields={[
                                    {value: 'title', label: 'Titre'},
                                    {value: 'reglementary_text', label: 'Texte'},
                                    {value: 'synthesis', label: 'Synthèse'},
                                    {value: 'technical_comment', label: 'Commentaire technique'},
                                ]}
                                selectedFields={getKeywordFields()}
                                onChange={value => addCriteria("keyword", value)}
                                onFieldChange={value => addCriteria("keyword_fields", toggleArrayValue(getKeywordFields() , value))}
                                value={criterias.keyword || ""} 
                            />
                            <FilterComponent type="select" name="source" onChange={value => addCriteria("source", value)} value={criterias.source} label="Source" options={sources} clearable />
                            <FilterComponent type="select" name="transmitter" onChange={value => addCriteria("transmitter", value)} value={criterias.transmitter} label="Emetteur" multiple options={transmitters} />
                            <FilterComponent type="select" name="text_type" onChange={value => addCriteria("text_type", value)} value={criterias.text_type} label="Type de texte" multiple options={text_types} />
                            <FilterComponent type="select" name="status" onChange={value => addCriteria("status", value)} value={criterias.status} label="Statut" options={getSheetStatus()} clearable />
                            <FilterComponent type="daterange" name="text_date" onChange={value => addCriteria("text_date", value)} value={criterias.text_date} label="Date du texte" />
                            <FilterComponent type="daterange" name="publication_date" onChange={value => addCriteria("publication_date", value)} value={criterias.publication_date} label="Date de publication" />
                            <FilterComponent type="text" name="text_number" onChange={value => addCriteria("text_number", value)} value={criterias.text_number} label="Numéro de texte" />
                            <FilterComponent type="text" name="nor" onChange={value => addCriteria("nor", value)} value={criterias.nor} label="Numéro NOR" />
                            <FilterComponent type="radio" name="repealed_text" onChange={value => addCriteria("repealed_text", value)} value={criterias.repealed_text} label="Texte abrogé" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                            <FilterComponent type="radio" name="cut_into_requirements" onChange={value => addCriteria("cut_into_requirements", value)} value={criterias.cut_into_requirements} label="Découpé en exigence" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                            <FilterComponent type="radio" name="major_text" onChange={value => addCriteria("major_text", value)} value={criterias.major_text} label="Texte majeur" blankLabel="Tous" options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]} />
                            <FilterComponent type="dropdown-tree-select" name="categories" onChange={value => addCriteria("categories", value)} value={criterias.categories} label="Domaines / Sous domaines / Thèmes" data={tree} mode="hierarchical" />
                            <FilterComponent
                                type="radio"
                                name="is_private"
                                onChange={value => addCriteria("is_private", value)}
                                value={criterias.is_private}
                                label="Fiche privée"
                                blankLabel="Tous"
                                options={[{value: "1", label: "Oui"}, {value: "0", label: "Non"}]}
                            />
                        </div>
                    </div>
                    <div className="bg-gris-25 border-b border-gris-60">
                        <div className="row">
                            <div className="col-md-9">
                                <button id="clearfilter-sheet" onClick={() => refresh(true)} type="button" className="btn btn-bleu-4 icon"><i className="icon-filtres-poubelle" aria-hidden="true"></i>Réinitialiser la recherche</button>
                            </div>
                            <div className="col-md-3 text-right">
                                <button id="search-sheet" type="submit" className="btn btn-primary">Rechercher</button>
                            </div>
                        </div>
                    </div>
                </section>

                <ListComponent 
                    id="referentials"
                    loading={loading}
                    selectable={false}
                    rows={rows}
                    columns={columns}
                    sorting={sorting}
                    direction={direction}
                    onSortingChange={updateSorting}
                    perpage={limit}
                    onPerpageChange={updateLimit}
                    page={page}
                    onPageChange={updatePage}
                    totalrows={totalrows}
                    globalActions={(
                        <>
                            <LoadButton
                                loading={exporting}
                                onClick={runExport}
                                label="Exporter"
                                id="export-dashboard"
                                className="btn btn-primary h25"
                                iconClass="icon-file-excel"
                            />
                            <Link id="new-sheet" to="/sheets/new" className="btn btn-primary h25 icon">
                                <i className="icon-boutons-ajouter-creer-affecter" aria-hidden="true"></i>&nbsp;Créer une fiche
                            </Link>
                        </>
                    )}
                />
            </form>
            <Modal isOpen={affecting} onRequestClose={() => setAffecting(false)} style={modalCustomStyles}>
                <AffectationForm
                    sheet={currentSheet}
                    perimeter={[]}
                    onClose={() => setAffecting(false)}
                    refresh={refresh}
                />
            </Modal>
        </div>
    )
}